* {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  text-decoration: none;
  font-family: "Noto Sans", sans-serif;
}

.appWrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.backgroundImage {
  background-image: url('./img/fundocampinas.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.backgroundImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: linear-gradient(to bottom, #123d18, #2a8033);
  z-index: 1;
}

.header,
#home {
  position: relative;
  z-index: 2;
}
